<template>
  <div>
    <Hero />
    <SkillSection :showViewAllButton="false" numberOfShownSkills="15" />
    <ProjectSection :showViewAllButton="true" :numberOfShownProjects="numberOfShownProjectsBasedOnScreenSize" />
  </div>
</template>

<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'

const { width } = useWindowSize()

const numberOfShownProjectsBasedOnScreenSize = computed(() =>
  width.value >= 1540 ? '8' : '9'
)

useSeoMeta({
  title: 'Home | Nikola Ivanovic 👨‍💻',
  ogTitle: '👨‍💻 Home | Nikola Ivanovic',
  description:
    'I am Nikola, a Full-Stack Software Engineer from Serbia. Welcome to my portfolio website, where you can learn more about me, my skills and my projects.',
  ogDescription:
    'Welcome to my portfolio website, where you can learn more about me 🧔‍♂️, my skills 💻 and my projects 🙃',
  ogImage: `${process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://nikola-ivanovic-software-engineer.com'
    }/images/nikola-ivanovic-software-engineer.jpg`,
  twitterCard: 'summary_large_image',
})
</script>
