<template>
  <div
    class="hero pt-[30px] min-h-[88vh] sm:min-h-screen bg-base-100 bg-gradient-to-b from-gray-200 to-gray-100 dark:gradient-to-b dark:from-base-200 dark:to-base-100">
    <div class="hero-content text-center">
      <div class="max-w-2xl">
        <Avatar />
        <h1 class="text-3xl font-bold">👋 Hello World!</h1>
        <p class="text-xl py-6">
          I am Nikola, a Full-Stack Software Engineer from Serbia.<br />
          <!-- I am experienced JavaScript/Node.js developer with main focus on: React.js, Next.js, Vue.js, Express.js,
          Nest.js, PostgreSQL, MySQL, MongoDB, TypeScript, GraphQL, Tailwind CSS and Docker. -->
          I am experienced JavaScript/Node.js developer
          but also I have some experience with Java, Python and PHP.<br />
          <!-- My main focus is on: React.js, Next.js, Vue.js, Express.js,
          Nest.js, PostgreSQL, MySQL, MongoDB, TypeScript, GraphQL, Tailwind CSS and Docker. -->
          <!-- I am
          mostly experienced with JavaScript/Node.js (MEVN, MERN, and MEAN stack)
          but I also have experience with Java, PHP and Python. -->
        </p>
      </div>
    </div>
  </div>
</template>
